<template>
    <div class="container-fluid px-0">
        <div class="row">
            <div class="col mb-4">
                <div class="card">
                    <div class="card-header">Card with links</div>
                    <div class="card-body">
                        <p>Quisque gravida aliquam diam at cursus, quisque laoreet ac lectus a rhoncusac tempus odio.</p><a class="card-link" href="#">Card link</a><a class="card-link" href="#">Another link</a>
                    </div>
                </div>
            </div>
            <div class="col mb-4">
                <div class="card">
                    <div class="card-header">Card with button</div>
                    <div class="card-body">
                        <p>Quisque gravida aliquam diam at cursus, quisque laoreet ac lectus a rhoncusac tempus odio.</p><a class="btn btn-primary" href="#">Go somewhere</a>
                    </div>
                </div>
            </div>
            <div class="col mb-4">
                <div class="card mb-3"><img class="card-img-top" src="/assets/images/346x106.png" alt="Placeholder">
                    <div class="card-body">
                        <p class="card-text-no-title">Quisque gravida aliquam diam at cursus, quisque laoreet ac lectus a rhoncusac tempus odio.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col mb-4">
                <div class="card mb-3"><img class="card-img-top" src="/assets/images/342x239.png" alt="Placeholder">
                    <div class="card-header">Card with image and links</div>
                    <div class="card-body">
                        <p>Quisque gravida aliquam diam at cursus, quisque laoreet ac lectus a rhoncusac tempus odio.</p><a class="card-link" href="#">Card link</a><a class="card-link" href="#">Another link</a>
                    </div>
                </div>
            </div>
            <div class="col mb-4">
                <div class="card mb-3"><img class="card-img-top" src="/assets/images/342x239.png" alt="Placeholder">
                    <div class="card-header">Card with image and button</div>
                    <div class="card-body">
                        <p>Quisque gravida aliquam diam at cursus, quisque laoreet ac lectus a rhoncusac tempus odio.</p><a class="btn btn-primary" href="#">Go somewhere</a>
                    </div>
                </div>
            </div>
            <div class="col mb-4">
                <div class="card"><img class="card-img-top" src="/assets/images/342x239.png" alt="Placeholder">
                    <div class="card-header">Card with image and list</div>
                    <div class="card-body">
                        <p class="mb-0">Quisque gravida aliquam diam at cursus, quisque laoreet ac lectus a rhoncusac tempus odio.</p>
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">Cras justo odio</li>
                        <li class="list-group-item">Vestibulum at eros</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col mb-4">
                <div class="card card-contrast">
                    <div class="card-header card-header-contrast card-header-featured">Featured</div>
                    <div class="card-body">
                        <div class="card-title">Card with Header</div>
                        <p>Curabitur eleifend augue non convallis condimentum. In iaculis eu est at iaculis Praesent finibus est sit amet orci posuere vulputate. </p><a class="btn btn-primary" href="#">Go somewhere</a>
                    </div>
                </div>
            </div>
            <div class="col mb-4 d-flex align-items-stretch">
                <div class="card card-contrast">
                    <div class="card-header card-header-contrast card-header-featured">Quote</div>
                    <div class="card-body">
                        <blockquote class="blockquote mb-0">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                            <footer class="blockquote-footer">Fringilla ante nec tincidunt.</footer>
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col mb-4">
                <div class="card card-contrast text-center">
                    <div class="card-header card-header-contrast card-header-featured">Featured</div>
                    <div class="card-body">
                        <div class="card-title">Card with Header and Footer</div>
                        <p>Curabitur eleifend augue non convallis condimentum. In iaculis eu est at iaculis Praesent finibus est sit amet orci posuere vulputate. </p><a class="btn btn-primary" href="#">Go somewhere</a>
                    </div>
                    <div class="card-footer card-footer-contrast text-muted">2 days ago</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col mb-4">
                <div class="card">
                    <div class="card-header">Card align left</div>
                    <div class="card-body">
                        <p>Proin risus orci, feugiat sit amet fringilla eget, aliquet vestibulum enim. Curabitur congue odio ut felis tincidunt, ac iaculis tellus lobortis.</p><a class="btn btn-primary" href="#">Go somewhere</a>
                    </div>
                </div>
            </div>
            <div class="col mb-4">
                <div class="card text-center">
                    <div class="card-header">Card align center</div>
                    <div class="card-body">
                        <p>Proin risus orci, feugiat sit amet fringilla eget, aliquet vestibulum enim. Curabitur congue odio ut felis tincidunt, ac iaculis tellus lobortis.</p><a class="btn btn-primary" href="#">Go somewhere</a>
                    </div>
                </div>
            </div>
            <div class="col mb-4">
                <div class="card text-right">
                    <div class="card-header">Card align right</div>
                    <div class="card-body">
                        <p>Proin risus orci, feugiat sit amet fringilla eget, aliquet vestibulum enim. Curabitur congue odio ut felis tincidunt, ac iaculis tellus lobortis.</p><a class="btn btn-primary" href="#">Go somewhere</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col mb-4">
                <div class="card"><img class="card-img-top" src="/assets/images/529x161.png" alt="Placeholder">
                    <div class="card-header">Card with image top</div>
                    <div class="card-body">
                        <p>Curabitur eleifend augue non convallis condimentum. In iaculis eu est at iaculis. Praesent finibus est sit amet orci posuere vulputate.</p>
                        <p> <small class="text-muted">Last update 3 mins ago</small></p>
                    </div>
                </div>
            </div>
            <div class="col mb-4">
                <div class="card">
                    <div class="card-header">Card with image bottom</div>
                    <div class="card-body">
                        <p>Curabitur eleifend augue non convallis condimentum. In iaculis eu est at iaculis. Praesent finibus est sit amet orci posuere vulputate.</p>
                        <p> <small class="text-muted">Last updated 3 mins ago</small></p>
                    </div><img class="card-img-bottom" src="/assets/images/529x161.png" alt="Placeholder">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col mb-4">
                <div class="card bg-dark text-white"><img class="card-img" src="/assets/images/1088x336.png" alt="Placeholder">
                    <div class="card-img-overlay card-img-overlay-center">
                        <div class="card-title">Card with image overlays</div>
                        <p>Curabitur eleifend augue non convallis condimentum. In iaculis eu est at iaculis. Praesent finibus est sit amet orci posuere vulputate.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="card-group">
                    <div class="card card-contrast"><img class="card-img-top" src="/assets/images/362x239.png" alt="Placeholder">
                        <div class="card-header">Card groups</div>
                        <div class="card-body">
                            <p>Curabitur eleifend augue non convallis condimentum. In iaculis eu est at iaculis. Praesent finibus est sit amet orci posuere vulputate.</p>
                        </div>
                        <div class="card-footer card-footer-contrast text-muted">Last updated 3 mins ago</div>
                    </div>
                    <div class="card card-contrast"><img class="card-img-top" src="/assets/images/362x239.png" alt="Placeholder">
                        <div class="card-header">Card groups</div>
                        <div class="card-body">
                            <p>Curabitur eleifend augue non convallis condimentum. In iaculis eu est at iaculis. Praesent finibus est sit amet orci posuere vulputate.</p>
                        </div>
                        <div class="card-footer card-footer-contrast text-muted">Last updated 3 mins ago</div>
                    </div>
                    <div class="card card-contrast"><img class="card-img-top" src="/assets/images/362x239.png" alt="Placeholder">
                        <div class="card-header">Card groups</div>
                        <div class="card-body">
                            <p>Curabitur eleifend augue non convallis condimentum. In iaculis eu est at iaculis. Praesent finibus est sit amet orci posuere vulputate.</p>
                        </div>
                        <div class="card-footer card-footer-contrast text-muted">Last updated 3 mins ago</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Cards',
    }
</script>
